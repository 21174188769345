import Hotspots from './src/Hotspots'

window.D3R = window.D3R || {}
window.D3R.Hotspots = []

const hotspots = document.querySelectorAll('.image-with-hotspots');

for (let imageWithHotspots of hotspots) {
    window.D3R.Hotspots.push(new Hotspots(imageWithHotspots))
}
