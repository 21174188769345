import Hotspot from "./Hotspot"

/**
 * Hotspots manager class
 */
export default class Hotspots
{
    /**
     * Constructor
     *
     * @param {HTMLElement} element The wrapping image div
     */
    constructor(element)
    {
        this.element = element
        this.hotspots = []
        this.currentSrc = null
        this.mediaQueries = []

        this.registerHotspots()

        const pictureElement = this.element.querySelector('picture');

        // We only care if the crop change
        if (pictureElement) {
            this.setupPictureChangeObserver()
        } else {
            this.repositionHotspots()
        }
    }

    /**
     * Register the hotspots into an array
     */
    registerHotspots()
    {
        const hotspots = this.element.querySelectorAll('ul.hotspots > li.hotspot')

        for (let hotspot of hotspots) {
            this.hotspots.push(new Hotspot(hotspot))
        }
    }

    /**
     * Setup the logic to change the positions of hotspots if the image is a
     * picture element - ie when the image crop changes
     */
    setupPictureChangeObserver()
    {
        const picture = this.element.querySelector('picture')
        const sources = picture.querySelectorAll('source')

        let initialMediaMatch

        for (const source of sources) {
            let mediaQuery = source.getAttribute('media')
            let mq = window.matchMedia(mediaQuery)

            this.mediaQueries.push(mq)

            if (mq.matches) {
                initialMediaMatch = mq
            }

            mq.addEventListener('change', event => {
                let updated = false

                // Loop through the sorted (hopefully) media queries - the first match should
                // be the one we're interested in
                for (const _mq of this.mediaQueries) {
                    if (_mq.matches) {
                        this.repositionHotspots(_mq.media)
                        updated = true
                        break
                    }
                }

                if (!updated) {
                    this.repositionHotspots()
                }
            })
        }

        // Sort the queries smallest size first
        this.mediaQueries.sort((mq1, mq2) => {
            const mq1Size = mq1.media.match(/\d+/)[0]
            const mq2Size = mq2.media.match(/\d+/)[0]

            return mq1Size - mq2Size
        })

        // Loop through the sorted (hopefully) media queries - the first match should
        // be the one we're interested in
        for (const _mq of this.mediaQueries) {
            if (_mq.matches) {
                initialMediaMatch = _mq
                break
            }
        }

        this.repositionHotspots(initialMediaMatch?.media)
    }

    /**
     * Reposition the hotspots - with an optional mediaquery
     * @param {String|undefined} forMediaQuery
     */
    repositionHotspots(forMediaQuery)
    {
        for (let hotspot of this.hotspots) {
            hotspot.setPositionStyles(forMediaQuery)
        }
    }
}
