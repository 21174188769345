import { computePosition, arrow, shift, offset, flip } from '@floating-ui/dom'

/**
 * Front End Hotspot Class
 */
export default class Hotspot
{
    /**
     * Constructor
     *
     * @param {HTMLElement} element Hotspot element
     */
    constructor(element)
    {
        this.element = element

        this.button = this.element.querySelector('.hotspot__button')
        this.content = this.element.querySelector(`#${ this.button.getAttribute('aria-describedby') }`)
        this.arrow = this.content.querySelector('.hotspot__arrow')

        this.positionData = JSON.parse(this.element.getAttribute('data-hotspot-positions'))

        this.bind()
    }

    /**
     * Bind event handlers
     */
    bind()
    {
        this.button.addEventListener('click', async event => {
            event.preventDefault()
            await this.togglePopover()
        })
    }

    /**
     * Get the default X position
     *
     * @returns {Number}
     */
    getDefaultXPosition()
    {
        return this.positionData.default[0]
    }

    /**
     * Get the default Y position
     * @returns {Number}
     */
    getDefaultYPosition()
    {
        return this.positionData.default[1]
    }

    /**
     * Get the X position for a certain media-query
     * If none found - use the default
     *
     * @param {String|undefined} mediaQuery
     * @returns {Number}
     */
    getXPositionForMediaQuery(mediaQuery)
    {
        if (typeof mediaQuery == 'undefined') {
            return this.getDefaultXPosition()
        }

        for (let maxWidth in this.positionData) {
            if (mediaQuery == maxWidth) {
                return this.positionData[maxWidth][0]
            }
        }

        return this.getDefaultXPosition()
    }

    /**
     * Get the Y position for a certain media-query
     * If none found - use the default
     *
     * @param {String|undefined} mediaQuery
     * @returns {Number}
     */
    getYPositionForMediaQuery(mediaQuery)
    {
        if (typeof mediaQuery == 'undefined') {
            return this.getDefaultYPosition()
        }

        for (let maxWidth in this.positionData) {
            if (mediaQuery == maxWidth) {
                return this.positionData[maxWidth][1]
            }
        }

        return this.getDefaultYPosition()
    }

    /**
     * Set the x/y position for the hotspot for a given media query
     * If the hotspot is outside the bounding box - hide it
     *
     * @param {String|undefined} mediaQuery
     * @returns {undefined}
     */
    setPositionStyles(mediaQuery)
    {
        const x = this.getXPositionForMediaQuery(mediaQuery)
        const y = this.getYPositionForMediaQuery(mediaQuery)

        if (x == -1 || y == -1) {
            this.hide()
            return
        }

        this.element.style.left = `${x}%`
        this.element.style.top = `${y}%`

        this.show()
    }

    /**
     * Show the hotspot
     */
    show()
    {
        this.element.style.display = 'list-item'
    }

    /**
     * Hide the hotspot
     */
    hide()
    {
        this.element.style.display = 'none'
    }

    /**
     * Toggle the hotspot content popover
     *
     * @returns {undefined}
     */
    async togglePopover()
    {
        if (this.content.style.display === 'block') {
            this.content.style.display = 'none'
            this.content.classList.remove('hotspot__content--active')
            return
        }

        this.content.style.display = this.content.style.display === 'block' ? 'none' : 'block'

        const openHotspots = document.querySelectorAll('.hotspot__content--active')

        for (let hotspot of openHotspots) {
            hotspot.style.display = 'none'
            hotspot.classList.remove('hotspot__content--active')
        }

        const { x, y, placement, middlewareData: { arrow: arrowData } } = await computePosition(
            this.button,
            this.content,
            {
                placement: 'right',
                middleware: [
                    offset(25),
                    flip(),
                    shift(),
                    arrow({
                        element: this.arrow,
                    }),
                ]
            }
        )

        this.setPlacementClass(placement)

        this.arrow.style.left = arrowData.x != null ? `${arrowData.x}px` : ''
        this.arrow.style.top = arrowData.y != null ? `${arrowData.y}px` : ''

        this.content.classList.add('hotspot__content--active')
        this.content.style.top = `${y}px`
        this.content.style.left = `${x}px`
    }

    /**
     * Set / Unset a placement class on the hotspot
     *
     * @param {String} placement placement from Floating UI
     */
    setPlacementClass(placement)
    {
        const base = 'hotspot__content-position'

        this.content.classList.remove(
            `${base}--top`,
            `${base}--top-start`,
            `${base}--top-end`,
            `${base}--right`,
            `${base}--right-start`,
            `${base}--right-end`,
            `${base}--bottom`,
            `${base}--bottom-start`,
            `${base}--bottom-end`,
            `${base}--left`,
            `${base}--left-start`,
            `${base}--left-end`,
        )

        this.content.classList.add(`${base}--${placement}`)
    }
}
